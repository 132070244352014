import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const About = () => {
  return (
    <div className="bg-amber dark:bg-gray-700">
      <Helmet>
        <title>About Us</title>
      </Helmet>

      <div className="xl:px-28 px-3 py-5">
        <div className="bg-body-img bg-cover bg-center bg-no-repeat lg:p-72 p-24"></div>
        <p className="text-left text-justify py-3">
          Joshua Saving and Credit Cooperative Limited Liability Society (JSCCoop LLS) was established in <span className="font-bold">September 1991</span> by 43 members committed to
          the principles of Christian faith with about 150,000.00 Birr capital in the city of Addis Ababa and has operated for more than <span className="font-bold">30 years</span>.
          It has become an exemplary and strong Membership-Based Saving & Credit Cooperative in Addis Ababa and the nearby towns.
          JSCCoop LLS has supported its member communities involved in small and medium development enterprises (SMDE) with financial loans at a
          lower rate than banks or other lending institutions.
        </p>
        <p className="text-left text-justify">
          This has been a practice for about three decades in and around the City of Addis Ababa.
          Its major overarching strategies include:
          <List>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Mobilize and encourage savings,</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Financial capacity building of its members through the provision
                of loans for its members who were engaged in business, social, and other cultural activities</ListItemText>
            </ListItem>
          </List>
          Until the last few years, evidence in the past
          shows that JSCCoop has positively impacted its members and the surrounding community at large in its commitment to serving its fellow members
          within its scope.
        </p>

        <div className="text-center py-3">
          <span className="text-3xl underline">Vision</span>
          <p className="text-left text-justify py-3">
            To see improved socio-economic living conditions of its members that will enable them to live a life of dignity and prosperity.
          </p>
        </div>

        <div className="text-center">
          <span className="text-3xl underline text-center">Mission</span>
          <p className="text-left text-justify py-3">
            To provide high quality, affordable and accessible financial, social, and business and building services to its members in order to create wider
            and best opportunities for a better standard of living.
          </p>
        </div>

        <div className="text-center">
          <span className="text-3xl underline text-center">Objective</span>
          <p className="text-left text-justify py-3">
            Facilitate the development of members' savings experience so that when they face a lack of funds, they can
            borrow in the form of a loan with reasonable interest and use it for work.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
