import api from "./api";

const login = async (emailorPhone: string, password: string) => {
  return await api.post("login", {
    emailorPhone: emailorPhone,
    password: password,
  });
};

const logout = async () => {
  return await api.post("logout");
};

const getUser = async () => {
  return await api.get("user");
};

const auth = {
  login,
  logout,
  getUser,
};

export default auth;
