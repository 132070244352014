import { Carousel } from "flowbite-react";
import React from "react";

interface Image {
    src: string;
    alt: string;
}

const Slides = () => {
    const images: Array<Image> = [
        {
            src: require("../assets/images/1.jpg"),
            alt: "first",
        },
        {
            src: require("../assets/images/2.jpg"),
            alt: "second",
        },
        {
            src: require("../assets/images/3.jpg"),
            alt: "third",
        },
        {
            src: require("../assets/images/4.jpg"),
            alt: "fourth",
        },
        {
            src: require("../assets/images/5.jpg"),
            alt: "fifth",
        }
    ];

    return (
        <Carousel slideInterval={3000} pauseOnHover className="rounded-xl">
            {images.map((image) => (
                <img alt={image.alt} src={image.src} />
            ))}
        </Carousel>
    );
};

export default Slides;