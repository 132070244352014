import { Dialog, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Documents = () => {
    const [show, setShow] = useState(false)
    const [name, setName] = useState("1")

    return (
        <div className="bg-amber dark:bg-gray-700">
            <Helmet>
                <title>Downloads</title>
            </Helmet>
            {/* <Typography align="center" fontWeight="bold" variant="h5" className="py-10">Reports</Typography> */}
            {/* <div className="lg:px-24 pb-10">
                <Table>
                    <TableHead>
                        <TableCell>
                            <Typography fontWeight={"bold"}>File Name</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"bold"}>Year</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"bold"}>Actions</Typography>
                        </TableCell>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>File Name</TableCell>
                            <TableCell>2014</TableCell>
                            <TableCell><ArrowDownwardIcon /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>File Name</TableCell>
                            <TableCell>2015</TableCell>
                            <TableCell><ArrowDownwardIcon /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography className="text-center">No Data</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div> */}

            <Typography align="center" fontWeight="bold" variant="h5" className="py-10">Templates</Typography>
            <div className="lg:px-24 pb-10">
                <Table>
                    <TableHead>
                        <TableCell>
                            <Typography fontWeight={"bold"}>File Name</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"bold"}>Actions</Typography>
                        </TableCell>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Loan Agreement</TableCell>
                            <TableCell>
                                <span onClick={() => { setShow(true); setName("1") }}>
                                    <ArrowDownwardIcon />
                                </span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Loan Form</TableCell>
                            <TableCell>
                                <span onClick={() => { setShow(true); setName("2") }}>
                                    <ArrowDownwardIcon />
                                </span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Bank Share</TableCell>
                            <TableCell>
                                <span onClick={() => { setShow(true); setName("3") }}>
                                    <ArrowDownwardIcon />
                                </span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Car Guarantee</TableCell>
                            <TableCell>
                                <span onClick={() => { setShow(true); setName("4") }}>
                                    <ArrowDownwardIcon />
                                </span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Save and Share Guarantee</TableCell>
                            <TableCell>
                                <span onClick={() => { setShow(true); setName("5") }}>
                                    <ArrowDownwardIcon />
                                </span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>House Guarantee</TableCell>
                            <TableCell>
                                <span onClick={() => { setShow(true); setName("6") }}>
                                    <ArrowDownwardIcon />
                                </span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Salary Guarantee</TableCell>
                            <TableCell>
                                <span onClick={() => { setShow(true); setName("7") }}>
                                    <ArrowDownwardIcon />
                                </span>
                            </TableCell>
                        </TableRow>
                        {/* <TableRow>
                            <TableCell colSpan={2}>
                                <Typography className="text-center">No Data</Typography>
                            </TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </div>
            <Dialog open={show} onClose={() => setShow(false)} maxWidth="lg">
                <DialogContent>
                    <iframe src={require(`../assets/templateFiles/${name}.pdf`)} title={`${name}`} width={1000} height={500} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Documents;
