import { Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import useStore from "../hook/store";
import { news } from "../common/type";
import { getNews } from "../services/get.service";
import ReactLoading from "react-loading";
import api from "../services/api";

const News = () => {
    const { user } = useStore()
    const [news, setNews] = useState(Array<news>)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getNews().then((res) => {
            setLoading(false)
            setNews(res.data)
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        })
    }, [])

    return (
        <div>
            <Helmet>
                <title>News</title>
            </Helmet>

            <div className="xl:px-28 px-3 py-5">
                <p className="text-3xl text-center underline">News</p>
                {user ? <Button variant="outlined">Add News</Button> : <></>}
                {
                    loading ?
                        <div className="flex flex-col items-center py-3"><ReactLoading type="spinningBubbles" color="#111" height={200} width={100} /></div>
                        :
                        news?.length > 0 ?
                            news.map((singleNews) => (
                                <div className="py-3 shadow-2xl">
                                    <div className="m-5 lg:flex gap-3">
                                        <img
                                            src={api.getUri() + singleNews.imgUrl}
                                            alt="News"
                                            className="lg:h-48 lg:w-56"
                                        />
                                        <div>
                                            <Typography align="center" variant="h6" fontWeight="bold">{singleNews.title}</Typography>
                                            <p className="text-left text-lg text-justify">{singleNews.body}</p>
                                            {/* <div className="grid grid-cols-3">
                                                <span>#alogrithm</span>
                                                <span>#alogrithm</span>
                                                <span>#alogrithm</span>
                                                <span>#alogrithm</span>
                                                <span>#alogrithm</span>
                                                <span>#alogrithm</span>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="text-small px-5 sm:grid grid-cols-2">
                                        <p>
                                            <span className="underline font-bold">Published On</span>: {new Date(singleNews.createdAt).toDateString()}
                                        </p>
                                        <p className="lg:text-right">
                                            <span className="underline font-bold">Updated On</span>: {new Date(singleNews.updatedAt).toDateString()}
                                        </p>

                                    </div>
                                </div>
                            ))
                            :
                            <div className="py-3 shadow-2xl">
                                <Typography align="center" variant="h6" fontWeight="bold">No News</Typography>
                            </div>
                }
            </div>
        </div>
    );
};

export default News;
