import { Navbar } from "flowbite-react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import useStore from "../hook/store";
import auth from "../services/auth";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl)
  // const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  // const open2 = Boolean(anchorEl2)
  // const [lang, setLang] = useState("en");
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate()
  const { user, reset } = useStore()

  // const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl2(event.currentTarget);
  // };
  // const handleClose2 = () => {
  //   setAnchorEl2(null);
  // };

  return (
    <Navbar fluid rounded className="bg-amber50 dark:bg-gray-900">
      <Navbar.Brand>
        <Link to="/">
          <img
            alt={"Joshua Logo"}
            className="mr-3 h-6 sm:h-9 rounded-full inline"
            src={require("../assets/images/JoshuaLogo.png")}
          />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            Joshua SACCO
          </span>
        </Link>
      </Navbar.Brand>
      <div className="flex md:order-2">
        {user
          ?
          (
            <div>
              <span className="info mr-2">Hi {user?.userName.slice(0, 5)}</span>
              <button
                onClick={async () => {
                  reset()
                  await auth.logout()
                  navigate('/')
                }}
                className="info mr-2"
              >
                Logout
              </button>
            </div>
          )
          :
          <></>
        }
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link>
          <Link to="/">Home</Link>
        </Navbar.Link>
        <Navbar.Link>
          <Link to="/about">About</Link>
        </Navbar.Link>
        <Navbar.Link>
          <Link to="/contact">Contacts</Link>
        </Navbar.Link>
        <Navbar.Link>
          <button onClick={handleClick}>Tools</button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}><Link to="/news">News</Link></MenuItem>
            <MenuItem onClick={handleClose}><Link to="/branchInfo">Branch Info</Link></MenuItem>
            {/* <MenuItem onClick={handleClose}><Link to="/profiles">Profiles</Link></MenuItem> */}
            <MenuItem onClick={handleClose}><Link to="/downloads">Downloads</Link></MenuItem>
          </Menu>
          {/* <Menu
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <MenuItem>Patients</MenuItem>
            <MenuItem>Staffs</MenuItem>
            <MenuItem>Transactions</MenuItem>
          </Menu> */}
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
