import React from "react";
import { Helmet } from "react-helmet";

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>Page Not Found</title>
            </Helmet>
            <div className="bg-cyan50 dark:bg-gray-700">
                <div className="text-black-100 text-center py-10 dark:text-white">
                    <span className="text-5xl">ooops</span>
                    <img
                        className="mx-auto w-1/2 h-1/2 opacity-60 py-5"
                        src={require("../assets/images/404.png")}
                        alt={"PageNotFound"}
                    />
                    <span className="text-5xl">Sorry, This page doesn't exist!</span>
                </div>
            </div>
        </>
    );
};

export default NotFound;
