import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV
      : process.env.REACT_APP_PROD,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export default instance;
