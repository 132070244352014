import api from "./api";

export const getNews = async () => {
  return await api.get("/news");
};

export const getHomeNews = async () => {
  return await api.get("/home/news");
};

export const getDocuments = async () => {
  return await api.get("/documents");
};
