import React, { useEffect, useState } from "react";
import Slides from "../components/Slides";
import { Helmet } from "react-helmet";
import CountUp from 'react-countup';
import { Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { GiCash } from "react-icons/gi";
import { GrResources } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import SavingsIcon from '@mui/icons-material/Savings';
import { news } from "../common/type";
import { getHomeNews } from "../services/get.service";
import ReactLoading from "react-loading";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import api from "../services/api";
import { LiaShareAltSolid } from "react-icons/lia";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Home = () => {
  const navigate = useNavigate();

  const [news, setNews] = useState(Array<news>)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getHomeNews().then((res) => {
      setLoading(false)
      setNews(res.data)
    }).catch((err) => {
      setLoading(false)
      console.log(err);
    })
  }, [])


  return (
    <div className="bg-amber dark:bg-gray-700">
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className="h-64 xl:h-screen">
        <Slides />
      </div>

      <div className="py-10 bg-red-300">
        <span className="text-5xl flex flex-col items-center py-5">
          What's New
        </span>
        {loading
          ?
          <div className="flex flex-col items-center">
            <ReactLoading type="spinningBubbles" color="#111" height={200} width={100} />
          </div>
          :
          news?.length > 0
            ?
            <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-5 lg:mx-20 sm:mx-10 md:mx-10">
              {
                news.map((singleNews) => (
                  <Card sx={{ bgcolor: "#fff" }}>
                    <CardContent>
                      <Typography fontWeight={"bold"} variant="h4" className="text-center">{singleNews.title}</Typography>
                      <img src={api.getUri() + singleNews.imgUrl} alt="News" />
                      <article className="text-wrap">
                        <p className="indent-5 text-center lg:text-justify xl:text-justify md:text-justify">
                          {singleNews.caption}{" "}
                          {/* <Link href="/news">More</Link> */}
                          <span onClick={() => navigate('news/')} className="text-blue-500 hover:underline hover:cursor-pointer">Read More</span>
                        </p>
                      </article>
                    </CardContent>
                  </Card>
                ))
              }
            </div>
            :
            <div className="grid">
              <Typography align="center" variant="h6" fontWeight="bold">No News</Typography>
            </div>
        }
      </div>

      <div className="pb-10">
        <span className="text-5xl underline flex flex-col items-center py-5">
          Our Services
        </span>
        <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-5 lg:mx-20 sm:mx-10 md:mx-10">
          <div className="items-center flex flex-col">
            <SavingsIcon sx={{ fontSize: 65 }} />
            <Typography fontWeight={"bold"} variant="h5">Savings</Typography>
            <p className="px-5">
              Saving plays an integral part in achieving your financial goals. Joshua offers a wide variety of savings
              products that allow for long-term and short-term savings with high-yield returns. Some of our saving products
              include :- <i>Mandatory Saving, Voluntary Saving, Time Deposit, Children Saving, Negotiated Rate Saving, etc</i>.
            </p>
          </div>
          <div className="items-center flex flex-col">
            <CreditScoreIcon sx={{ fontSize: 65 }} />
            <Typography fontWeight={"bold"} variant="h5">Loans</Typography>
            <p className="px-5">
              Joshua has a wide range of loan products customized to fit its varied niche of members' needs and obligations.
              Our loan products are accessible at flexible repayment terms and affordable interest rates processed with a
              processing turnaround time of a few days. Some of our loan products include :- <i>Business Loan, Health Loan,
                Education Loan, Social Loan, Car Loan, Housing Loan, etc</i>.
            </p>
          </div>
          <div className="items-center flex flex-col">
            <LiaShareAltSolid size={65} />
            <Typography fontWeight={"bold"} variant="h5">Share</Typography>
            <p className="px-5">
              Every member of Joshua is also made owner by allowing him/her to purchase a minimum number of shares from the
              cooperative. The price of each share is ETB 1,000.00. Dividends are calculated for each member at the end of
              the budget year. On top of that members can use the share and save money as collateral for their loan.
            </p>
          </div>
        </div>
      </div>

      <div className="pb-10">
        <span className="lg:text-4xl text-2xl underline flex flex-col items-center py-5">
          Requirements for a Membership
        </span>
        <p className="lg:mx-20 mx-5">
          If you want to join our association, you have to pay only 2500 Birr.
          <List>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Buying at least one lot/share when one lot is 1000 Birr</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Saving 500 Birr</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Registration 1000 Birr only for one time</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Two 3x4 size photographs taken recently</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Only a renewed ID or passport or driver's license can be added outside the registry</ListItemText>
            </ListItem>
          </List>
        </p>
      </div>

      <div className="pb-10">
        <span className="lg:text-4xl text-2xl underline flex flex-col items-center py-5">
          Requirements to get a Loan
        </span>
        <p className="lg:mx-20 mx-5">
          For all types of loan products
          <List>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>The member should save regularly for at least six consecutive months that amount 25% of the requested/needed loan amount.</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Should bring a letter from her/his employer institution that specifies the monthly income of the member.</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Business operators should bring a renewed trade license or proposal.</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Should bring collateral for the loan.</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Should bring one member from the cooperative as a cosigner.</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon><ArrowForwardIosIcon /></ListItemIcon>
              <ListItemText>Should be willing to pay 1.5% service charge and 2% insurance.</ListItemText>
            </ListItem>
          </List>
        </p>
      </div>

      <div className="pb-10">
        <span className="text-5xl underline flex flex-col items-center py-5">
          Statistics
        </span>
        <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-5 lg:mx-20 sm:mx-10 md:mx-10">
          <Card sx={{ bgcolor: "#fecaca" }}>
            <CardContent>
              <div className="font-bold text-3xl text-center">
                <div className="items-center flex flex-col"><GiCash size={60} /></div>
                <div>Disburesed Loan</div>
                <CountUp end={900000000} start={0} duration={5} redraw={true} enableScrollSpy={true} />+
              </div>
            </CardContent>
          </Card>
          <Card sx={{ bgcolor: "#fecaca" }}>
            <CardContent>
              <div className="font-bold text-3xl text-center">
                <PeopleAltIcon sx={{ fontSize: 60 }} />
                <div>Members Count</div>
                <CountUp end={32510} start={0} duration={5} redraw={true} enableScrollSpy={true} />+
              </div>
            </CardContent>
          </Card>
          <Card sx={{ bgcolor: "#fecaca" }}>
            <CardContent>
              <div className="font-bold text-3xl text-center">
                <div className="items-center flex flex-col"><GrResources size={60} /></div>
                <div>Capital</div>
                <CountUp end={253832686} start={0} duration={5} redraw={true} enableScrollSpy={true} />+
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Home;
