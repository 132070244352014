import { Card, CardContent, Link, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

const BranchInfo = () => {
  return (
    <div className="bg-amber dark:bg-gray-700">
      <Helmet>
        <title>Branch Info</title>
      </Helmet>
      <Typography align="center" fontWeight="bold" variant="h5" className="py-10">Branch Informations</Typography>
      <div className="grid lg:grid-cols-2 lg:mx-24 gap-3 pb-5">
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>Olympia</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />ደንበል አደባባይ ዘመን ባንክ ባለበት ህንጻ በኩል 100 ሜትር ገባ ብሎ ኢስት ገስት
                  ሐዉስ አፓርትመንት አጠገብ ወይም የቀድሞው አድማስ ዩኒቨርስቲ ኮሌጅ የነበረበት ህንፃ</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251115584407" className="px-5">+251 115584407</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251115582066" className="px-5">+251 115582066</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>Piassa</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />ከጊዮርጊስ ወደ አዲሱ ገበያ መሄጃ ሶራንባ ሆቴል ፊት ለፊት ካፒታል ህንጻ ላይ</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+25111281200" className="px-5">+251 11281200</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+25111280330" className="px-5">+251 11280330</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>Kality</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />ቶታል ካፍደም ህንጻ 1ኛ ፎቅ</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251114707211" className="px-5">+251 114707211</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+25111402199" className="px-5">+251 11402199</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251993531530" className="px-5">+251 993531530</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>Lideta</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />ልደታ ማርያም ቤ/ያን ፊት ለፊት አዋሽ ባንክ ህንጻ ላይ</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251115574092" className="px-5">+251 115574092</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251906384747" className="px-5">+251 906384747</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>Ayer Tena</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />ሳሚ ካፌ ጎን</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251113693978" className="px-5">+251 113693978</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251113694179" className="px-5">+251 113694179</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>Megenagna</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />ታላቁ ተልዕኮ ህንጻ ላይ</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251116661102" className="px-5">+251 116661102</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251116660987" className="px-5">+251 116660987</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>Ayat</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />ጣፎ ታክሲ መያዣ ሹሚ በዛ ህንጻ ላይ</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251974308688" className="px-5">+251 974308688</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>5 Kilo</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />ብሔራዊ ሙዝየም ፊት ለፊት ራዲካል ህንጻ ላይ</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251906414747" className="px-5">+251 906414747</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251111542778" className="px-5">+251 111542778</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>Filance / Medhanialem</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />መድሃኒአለም ፊላንስ ህንጻ ላይ</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251993004029" className="px-5">+251 993004029</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251112591402" className="px-5">+251 112591402</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>Kera</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />ኢየሱስ ጌታ ህንጻ ጎን ሱፊ ህንጻ ላይ</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251974309188" className="px-5">+251 974309188</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251114628921" className="px-5">+251 114628921</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>Jemo</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />ኦልማርት ፊት ለፊት ናቲ እንግዳ ማረፍያ ህንጻ ላይ</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251974308892" className="px-5">+251 974308892</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251114719078" className="px-5">+251 114719078</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4" align="center" fontWeight={"bold"}>Semit Safari</Typography>
            <div className="lg:flex gap-3 text-justify">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                alt="Branch"
                className="lg:h-48 lg:w-56"
              />
              <p className="py-3">
                <Typography variant="h6"><LocationOnIcon fontSize="large" />ፍየል ቤት ወደ መገናኛ መሄጃ ማና ህንፃ  ላይ</Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251906804747" className="px-5">+251 906804747</Link></Typography>
                <Typography variant="h6"><ContactPhoneIcon fontSize="large" /><Link href="callto:+251116661333" className="px-5">+251 116661333</Link></Typography>
              </p>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default BranchInfo;
