import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Layout from './layout/Layout';
import NotFound from './pages/NotFound';
import About from './pages/About';
import Contact from './pages/Contact';
import News from './pages/News';
import BranchInfo from './pages/BranchInfo';
// import Profiles from './pages/Profiles';
import Documents from './pages/Documents';
// import Login from './pages/Login';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route element={<Home />} path='/' />
          <Route element={<About />} path='/about' />
          <Route element={<Contact />} path='/contact' />
          <Route element={<News />} path='/news' />
          <Route element={<BranchInfo />} path='/branchInfo' />
          {/* <Route element={<Profiles />} path='/profiles' /> */}
          <Route element={<Documents />} path='/downloads' />
          {/* <Route element={<Login />} path='/login' /> */}
          <Route element={<NotFound />} path='*' />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
