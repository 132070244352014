import React from "react";
import { Link } from "react-router-dom";
import { Link as ALink } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Typography } from "@mui/material";

const Fotter = () => {
  return (
    <footer className="bg-amber50 dark:bg-gray-900 ">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="lg:grid lg:grid-cols-4">
          <div className="mb-6 md:mb-0">
            <Link to="/" className="flex items-center pb-3">
              <img
                src={require("../assets/images/JoshuaLogo.png")}
                className="h-8 mr-3 rounded-full"
                alt={"JoshuaLogo"}
              />
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                Joshua SACCO
              </span>
            </Link>
            <span className="font-bold">Save, Take loan for a purpose</span>
          </div>
          <div className="lg:py-0 py-3">
            <Typography fontWeight={"bold"}>Main Branch Contacts</Typography>
            <Typography><ALink href="callto:+251115584414">+251 11 55 84 4(14/15/16/17/18)</ALink></Typography>
            <Typography><ALink href="callto:+251115582970">+251 11 55 82 970</ALink></Typography>
          </div>
          <div className="lg:py-0 py-3">
            <Typography fontWeight={"bold"}>Bank Accounts</Typography>
            <Typography>Awash: 01303075291800</Typography>
            <Typography>Birhan 1: 2500050003781</Typography>
            <Typography>Birhan 2: 1501610065593</Typography>
            <Typography>CBE: 1000000987666</Typography>
          </div>
          <div className="grid grid-cols-1 gap-8 sm:gap-8 sm:grid-cols-1">
            <div>
              <h2 className="mb-2 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Developer Contact
              </h2>
              <ul className="text-gray-700 grid grid-cols-1 gap-1 sm:gap-1 sm:grid-cols-1 dark:text-gray-400 font-medium pb-2">
                <li className="">
                  <span >Eagle Sight Technologies P.L.C.</span>
                </li>
                <li className="">
                  <a
                    rel="noreferrer"
                    href="callto:+251911653541"
                    className="hover:text-gray-900 dark:hover:text-white"
                  >
                    +251 911 653541
                    <span className="sr-only">+251911653541</span>
                  </a>
                </li>
                <li className="">
                  <a
                    rel="noreferrer"
                    href="callto:+251949190748"
                    className="hover:text-gray-900 dark:hover:text-white"
                  >
                    +251 949 190748
                    <span className="sr-only">+251949190748</span>
                  </a>
                </li>
              </ul>
              <button onClick={() => window.scrollTo(0, 0)}>Go To <ArrowUpwardIcon /></button>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © ፳፻፩፯ - 2024/25{" "}
            <Link to="/" className="hover:underline">
              Joshua SACCO
            </Link>
            &nbsp;All Right Resereved
          </span>
          <div className="flex mt-4 space-x-5 sm:justify-center sm:mt-0">

            <a
              target="_blank"
              rel="noreferrer"
              href="https://t.me/saccosjoshua"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="4 5 8 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span className="sr-only">Telegram page</span>
            </a>
            <a
              // target="_blank"
              rel="noreferrer"
              href="#"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 8 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span className="sr-only">Facebook page</span>
            </a>
            <a
              // target="_blank"
              rel="noreferrer"
              href="#"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
              <span className="sr-only">Instagram</span>
            </a>
            <a
              href="#"
              // target="_blank"
              rel="noreferrer"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="h-4 w-4"
              >
                <path
                  fill="currentColor"
                  d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                />
              </svg>
              <span className="sr-only">Tiktok page</span>
            </a>
          </div>
        </div>
      </div>
    </footer >
  );
};

export default Fotter;
